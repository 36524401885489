// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;800&display=swap");



@font-face {
  font-family: 'dastnevis';
  src: local('dastnevis'), url(./fonts/dastnevis.otf) format('otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


* {
  font-family: "dastnevis" !important;
}



.mainBody {
  min-height: 90vh;
  padding-top: 120px;
}

.bg-white {
  background-color: white !important;
}



.divider-left {
  border-left: 2px solid #ced2d8;
}

.divider-top {
  border-top: 1px solid var(--secondary);
}

.c-sidebar-nav-title {
  font-size: 1em;
}


.c-app {
  color: #121453
}