// Here you can add other styles

.c-header-nav-link  {
  color: rgb(255,255,255) !important;
}

.c-header-nav-link {
  text-decoration-line: none
}

.c-header-nav-link:hover {
  border-bottom-style: solid;
  border-bottom-color: #a15e3e;
  border-bottom-width: 3px;
}

.active {
  border-bottom-style: solid;
  border-bottom-color: #a15e3e;
  border-bottom-width: 3px;
}

.c-header {
  border-bottom: none;
}

.c-subheader {
  background-color: #e6e3e3;//#ff9600;
}

.c-subheader  .active {

  border-bottom-style: solid;
  border-bottom-color: #a15e3e;
  border-bottom-width: 3px;
}



.c-subheader-nav a {
  color: black !important;
  text-decoration: none;
}



.table thead {
  color: #a15e3e;
}


.nav-tabs .nav-item a {
  color: black
}

.nav-tabs .active {
  border-color: #a15e3e;
}

.timeline-line {
  width: 90px;
  height: 4px;
  top: 17%;
  left: 100%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .timeline-line {
    width: 70px;
  }
}
